import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import SetBodyColor from "./SetBodyColor";

export default function Header() {
  const Site_Url = "https://namechangeserviceintarntaran.co.in/";
  const BASE_URL = Site_Url + "admin/API/";

  //website Setting API
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  SetBodyColor({color:`${webSetting.siteColor}`});

  const [languagedata, setlanguagedata] = useState([]);
  useEffect(() => {
    const Alllanguagedata = async () => {
      const response = await fetch(`${BASE_URL}Alllanguagedata.php`);
      const jsonData = await response.json();
      setlanguagedata(jsonData);
    };
    Alllanguagedata();
  }, [BASE_URL]);

  return (
    <>
      <Helmet>
        <title>{webSetting.matatitle}</title>
        <link rel="icon" type="image/x-icon" href={webSetting.favicon} />
      </Helmet>

      <a
        href={`https://wa.me/${webSetting.whatsaap}?text=Hi, I would like to speak to a Name Change Service`}
        className="wa-float-img-circle"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa fa-whatsapp" aria-hidden="true"></i>
      </a>

      <a
        href={`tel:${webSetting.number}`}
        className="call-float-img-circle"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa fa-phone" aria-hidden="true"></i>
      </a>

      <section className="topbar_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-6">
              <div className="topbar_div">
                <p>
                  <i
                    className="fa fa-volume-control-phone"
                    aria-hidden="true"
                  ></i>
                  <span>CALL US: </span> {webSetting.number}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-6">
              <div className="topbar_div topbar_right">
                <p>
                  <i className="fa fa-quote-left" aria-hidden="true"></i>
                  <span> REQUEST A:</span> FREE CONSULTATION{" "}
                </p>
              </div>
            </div>
            
          </div>
        </div>
      </section>

      <section className="header_space">
        <nav className="navbar navbar-expand-lg nav_header">
          <div className="container">
            <a className="navbar-brand logo" href="/">
              <img src={webSetting.website_logo} alt="" title="" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse nav_ul menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    className="nav-link cool-link"
                    aria-current="page"
                    exact="true"
                    to="/"
                  >
                    Home
                  </NavLink>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link BlogDropbtn cool-link"
                    data-bs-toggle="dropdown"
                  >
                    Blog
                  </Link>
                  <ul className="dropdown-menu BlogDropbtnMenu">
                    {languagedata.map((value, index) => (
                      <li key={index}>
                        <NavLink
                          className="BlogDropbtnlink dropdown-item cool-link"
                          to={`/blog/${value.language}`}
                        >
                          {" "}
                          {value.long_title}{" "}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="nav-item">
                  <NavLink
                    className="nav-link cool-link"
                    exact="true"
                    to="/service"
                  >
                    Services{" "}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link cool-link"
                    exact="true"
                    to="/contact"
                  >
                    Contact Us{" "}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
}
